<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-modal v-model:visible="visible" v-if="visible" width="80%" title="选择小车" :footer="null">
    <suy-table :reload="loadData" v-model:columns="columns" >
      <template #search>
        <a-form ref="formQueryRef" layout="inline" :model="searchForm">

          <a-row :gutter="[24,24]">
            <a-col :span="6">
              <a-form-item label="录入时间起">
                <a-date-picker v-model:value="searchForm.orderTimeStart" valueFormat="YYYY-MM-DD" :format="dateFormat" />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="卸车时间起">
                <a-date-picker v-model:value="searchForm.unloadTimeStart" valueFormat="YYYY-MM-DD" :format="dateFormat" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="起始地点" name="driver">
                <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddress" placeholder="" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="合同号" name="orderId">
                <a-input class="" v-model:value="searchForm.orderId" />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="录入时间止">
                <a-date-picker v-model:value="searchForm.orderTimeEnd" valueFormat="YYYY-MM-DD" :format="dateFormat" />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="卸车时间止">
                <a-date-picker v-model:value="searchForm.unloadTimeEnd" valueFormat="YYYY-MM-DD" :format="dateFormat" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="目的地点" name="carNo">
                <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddress" style="height: 38px;" placeholder="" />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="车架号" name="carNo">
                <a-input class="" v-model:value="searchForm.carNo" />
              </a-form-item>
            </a-col>
            <a-col :span="6">

            </a-col>
            <a-col :span="6">

            </a-col>
            <a-col :span="6">

            </a-col>
            <a-col :span="6" style="text-align: right;">
              <a-form-item>
                <a-button type="primary" @click="onSearch">
                  <template #icon>
                    <SearchOutlined />
                  </template>查询
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>

        </a-form>
      </template>
      <template #toolbarRight>
        <a-button type="primary" @click="selectVehicle" :disabled="disabledVehicle">
          选择小车
        </a-button>
      </template>
      <a-table :columns="columns" :rowKey="(record,index)=>{return index+record.vinNo}" :data-source="listData" :pagination="pagination" :loading="loading" :style="{overflow: 'auto' }" :scroll="{ x: 2500, y: 350 }" size="middle" :header-cell-style="{background:'#eef1f6',color:'#606266'}" bordered @change="handleTableChange" :row-selection="rowSelect">

      </a-table>
    </suy-table>
  </a-modal>
</template>
<script>
import { toRefs, reactive, onMounted } from 'vue'
import { vehicleAll } from '@/api/transport/truck'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
export default {
  components: {
    SuyTable
  },
  setup (props, context) {
    const state = reactive({

      loading: false,
      disabledVehicle: true,
      visible: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        carNo: '',
        orderTimeStart: '',
        orderTimeEnd: '',
        unloadTimeStart: '',
        unloadTimeEnd: '',
        startAddress: {},
        endAddress: {}
      },
      listData: [],
      columns: [
        {
          title: '小车车牌',
          dataIndex: 'vinNo',
          width: '100px',
          key: 'vinNo'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman',
          ellipsis: true
        },
        {
          title: '客户来源',
          dataIndex: 'salesman',
          key: 'salesman',
          ellipsis: true
        },
        {
          title: '结算方式',
          dataIndex: 'orderSettlement.label',
          key: 'orderSettlement.label',
          ellipsis: true
        },

        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand',
          ellipsis: true
        },

        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model',
          ellipsis: true
        },
        {
          title: '运输状态',
          dataIndex: 'vehicleTransStatus.label',
          key: 'vehicleTransStatus.label',
          ellipsis: true
        },
        {
          title: '当前类型',
          dataIndex: 'transportType.label',
          key: 'transportType.label',
          ellipsis: true
        },
        {
          title: '当前状态',
          dataIndex: 'VehicleTransNowStatusEnum.label',
          key: 'VehicleTransNowStatusEnum.label',
          ellipsis: true
        },
        {
          title: '中转',
          dataIndex: 'isTransfer.label',
          key: 'isTransfer.label',
          ellipsis: true
        }
      ],
      form: {},
      datakeyAll: [],
      getname: '',
      rowSelect: {
        onChange: (datakey, selectedRows) => {
          state.datakeyAll = selectedRows
          if (state.datakeyAll.length > 0) {
            state.disabledVehicle = false
          } else {
            state.disabledVehicle = true
          }
        }
      }
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await vehicleAll({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.listData = records
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(loadData)
    const onSwitch = (parameter) => {
      state.visible = parameter
    }
    const onclick = (recode, type) => {
      switch (type) {
        case 1:
          if (recode == null) {
            recode = {}
          }
          break

        default:
          message.success('出现错误' + type)
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
      state.searchForm.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
      state.searchForm.endAddress.area = value[2]
    }

    const selectVehicle = () => {
      context.emit('onCar', state.datakeyAll)
      onSwitch(false)
    }

    return {
      ...toRefs(state),
      onSearch,
      loadData,
      selectVehicle,
      setEndAddress,
      setStartAddress,
      handleTableChange,
      onSwitch,
      onclick
    }
  }
}
</script>
