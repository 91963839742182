<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">

        <a-row :gutter="[24,12]">

          <a-col :span="8">
            <a-form-item label="异常类型">
              <a-select v-model:value="searchForm.exceptionType" allowClear>
                <a-select-option v-for="type  in exceptionTypes" :key="type[1]"> {{ type[0]}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="异常原因">
              <a-select v-model:value="searchForm.exceptionSubType" allowClear>
                <a-select-option v-for="type  in exceptionExplains" :key="type[1]"> {{ type[0]}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="上报机构">
              <a-select v-model:value="searchForm.orgId" show-search @select="onSelect" @search="handleSearch" allowClear>
                <a-select-option v-for="parentOrg in orgList" :key="parentOrg.id">
                  {{ parentOrg.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item label="上报姓名" name="explainer">
              <a-input v-model:value="searchForm.explainer" />
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item label="小车车牌" name="carNo">
              <a-input v-model:value="searchForm.carNo" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="上报开始时间">
              <a-date-picker v-model:value="searchForm.startSqlTime" valueFormat="YYYY-MM-DD" :format="dateFormat" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="上报结束时间">
              <a-date-picker v-model:value="searchForm.endSqlTime" valueFormat="YYYY-MM-DD" :format="dateFormat" />
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-form-item>
              <a-button type="primary" @click="onSearch">
                <template #icon>
                  <SearchOutlined />
                </template>查询
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
    </template>
    <template #toolbarRight>
      <!-- <a-button type="primary" @click="onClick('',3)">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        异常上报
      </a-button> -->
      <a-button @click="onClick('',2)">
        导出Excel
      </a-button>
    </template>
    <a-table :columns="[sortColumn,...columns]" :rowKey="(record,index)=>{return index}" :data-source="listData" :pagination="pagination" size="small" :loading="loading" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{index+1}}
      </template>
      <template #fileUrl="{record}">
        <a-image v-if="record.fileId !== null" :width="40" :src="record.fileUrl"  :preview="record.preview" @click="handlePreview(record)"/>
      </template>
      <template #operation="{ record }">
        <a @click="onClick(record,4)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm title="确定删除?" @confirm="onClick(record,5)">
          <a>删除</a>
        </a-popconfirm>

      </template>
    </a-table>
  </suy-table>
  <carImg ref="carIMG" />
  <addI ref="addi" @onLoadData='loadData' />
</template>
<script>
import { toRefs, reactive, onMounted, ref } from 'vue'
import { page, dele, exportXls } from '@/api/transport/orderException'
import SuyTable from '@/components/SuyTable'
import transportType from '../TransportType/TransportType.json'
import { message } from 'ant-design-vue'
import carImg from '../car/carImgUplad'
import addI from './additional'
// import { getOrgList } from '@/api/global'
import { useStore } from 'vuex'
export default {
  data () {
    return {
      exceptionExplains: transportType.exceptionExplain,
      exceptionTypes: transportType.exceptionType
    }
  },
  components: {
    SuyTable,
    carImg,
    addI
  },
  setup () {
    const store = useStore()
    const state = reactive({
      carIMG: ref(null),
      addi: ref(null),
      loading: false,
      orgList: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        exceptionSubType: '',
        exceptionType: '',
        explainer: '',
        carNo: '',
        explainTime: ''
      },
      listData: [],
      sortColumn: { title: '序号', width: 50, slots: { customRender: 'sort' } },
      columns: [
        {
          title: '合同号',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '异常类型',
          dataIndex: 'exceptionType.label',
          key: 'exceptionType.label'
        },
        {
          title: '异常原因',
          dataIndex: 'exceptionSubType.label',
          key: 'exceptionSubType.label'
        },
        {
          title: '异常说明',
          width: 300,
          dataIndex: 'exceptionExplain',
          key: 'exceptionExplain',
          ellipsis: true
        },
        {
          title: '车架号',
          dataIndex: 'carNo',
          key: 'carNo'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          key: 'transportType.label'
        },
        {
          title: '运输状态',
          dataIndex: 'transStatus.label',
          key: 'transStatus.label'
        },
        {
          title: '上报时间',
          dataIndex: 'explainTime',
          key: 'explainTime'
        },
        {
          title: '图片',
          // width: 200,
          dataIndex: 'fileUrl',
          key: 'fileUrl',
          slots: {
            customRender: 'fileUrl'
          }
        },
        {
          title: '上报人名',
          dataIndex: 'explainer',
          key: 'exceptionExplain'
        },
        {
          title: '上报机构',
          dataIndex: 'orgName',
          key: 'orgName'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          slots: {
            customRender: 'operation'
          }
        }
      ],
      form: {}
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.listData = records
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(loadData)
    const onClick = (recode, type) => {
      switch (type) {
        case 1:
          if (recode == null) {
            recode = {}
          }
          break
        case 2:
          exportXls({
            ...state.searchForm,
            current: state.pagination.current,
            size: state.pagination.pageSize
          }, '异常报表').then(res => {
            if (res === 10000) {
              message('导出成功！')
            }
          })
          break
        case 3:
          state.addi.onSwitch(true)
          break
        case 4:
          state.addi.onData(recode)
          break
        case 5:
          dele(recode.id, state.data).then((res) => {
            if (res.code === 10000) {
              message.success(res.msg)
            }
            state.fromRef.resetFields()
          })
          loadData()

          break
        default:
          message.success('出现错误' + type)
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // getOrgList({}).then(res => { state.orgList = res.data })
    state.orgList = store.state.app.orgList

    const handlePreview = (item) => {
      item.preview = true
      item.fileUrl = item.fileRealUrl
    }
    return {
      ...toRefs(state),
      onSearch,
      loadData,
      handleTableChange,
      onClick,
      handlePreview
    }
  }
}
</script>
