<style lang="less" scoped>
.ransport_search_input {
  width: 187px !important;
}
.ransport_textarea {
  width: 550px !important;
}
</style>
<template>

  <a-drawer title="异常上报" width="43%" :placement="placement" :closable="true" :visible="visible" :maskClosable="false" @close="onSwitch(false)" v-if="visible">
    <a-form ref="fromRef" :model="data" :rules="rules">
      <a-row :gutter="2">
        <a-col :span="24">
          <a-form-item label="上报小车" name="carNo">
            <a-input @click="onclick('',2)" v-model:value="data.carNo" :disabled='datatype' placeholder="选择小车" />

          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="异常类型" name="exceptionType">
            <a-select v-model:value="data.exceptionType" class="ransport_search_input" allowClear>
              <a-select-option v-for="v in exceptionTypes" :key="v[1]"> {{ v[0]}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="异常原因" name="exceptionSubType">
            <a-select v-model:value="data.exceptionSubType" class="ransport_search_input" allowClear>
              <a-select-option v-for="j in exceptionExplains" :key="j[1]"> {{ j[0]}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="异常说明" name="exceptionExplain">
            <a-textarea v-model:value="data.exceptionExplain" class="ransport_textarea" :maxlength="200" showCount />
          </a-form-item>
        </a-col>
        <a-col :span="24" v-if="data.fileId !== null">
          <a-image :width="200" :src="data.fileRealUrl" :preview="preview" @click="handlePreview()"/>
        </a-col>
        <a-col :span="3">
          <a-upload v-model::file-list="fileList" :remove="handleRemove" list-type="picture" :before-upload="beforeUpload">
            <a-button>
              上传照片
            </a-button>
          </a-upload>
        </a-col>
        <a-col :span="3">
          <a-form-item v-if="!datatype">
            <a-button type="primary" @click="onclick('',1)" :loading="loading">
              添加
            </a-button>
          </a-form-item>
          <a-form-item v-else>
            <a-button type="primary" @click="onclick('',3)" :loading="loading">
              修改
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <trans ref="trans" @onCar='onCar' />
  </a-drawer>
</template>
<script>
import { toRefs, reactive } from 'vue'
import { batch, edit } from '@/api/transport/orderException'
import transportType from '../TransportType/TransportType.json'
import trans from './trans'
import { message } from 'ant-design-vue'
import { fileUpload as upload } from '@/api/marketing/modList'

export default {
  components: {
    trans
  },
  data () {
    return {
      exceptionExplains: transportType.exceptionExplain,
      exceptionTypes: transportType.exceptionType

    }
  },

  setup (props, context) {
    const state = reactive({
      car: {

      },
      preview: false,
      fileList: [],
      loading: false,
      fromRef: null,
      trans: null,
      datatype: false,
      data: {
        exceptionSubType: '',
        fileId: '',
        exceptionExplain: '',
        exceptionType: '',
        carNo: ''
      },
      rules: {
        exceptionType: [{ required: true, message: '请选择异常类型', trigger: 'change', type: 'number' }],
        exceptionSubType: [{ required: true, message: '请选择异常原因', trigger: 'change', type: 'number' }],
        carNo: [{ required: true, message: '请选择小车', trigger: 'change' }],
        exceptionExplain: [{ required: true, message: '请填写异常说明', trigger: 'change' }]
      },
      visible: false,

      columns: [
        {
          title: '异常类型',
          dataIndex: 'exceptionType.label',
          key: 'exceptionType.label',
          ellipsis: true
        },
        {
          title: '异常原因',
          dataIndex: 'exceptionSubType.label',
          key: 'exceptionSubType.label',
          ellipsis: true
        }, {
          title: '异常说明',
          dataIndex: 'exceptionExplain',
          key: 'exceptionExplain'
        }
      ],
      form: {}
    })
    const onSwitch = (parameter) => {
      if (parameter === false) {
        state.data = {
          exceptionSubType: '',
          exceptionExplain: '',
          type: '',
          carNo: ''
        }
        state.car = {
          carNo: ''
        }
        state.datatype = false
        context.emit('onLoadData')
      }
      state.visible = parameter
    }
    const onclick = (recode, type) => {
      switch (type) {
        case 1:
          state.fromRef
            .validate()
            .then(() => {
              state.loading = true
              if (state.fileList.length > 0) {
                const formData = new FormData()
                formData.append('file', state.fileList[0])
                upload(formData).then(res => {
                  if (res.code === 10000) {
                    state.data.fileId = res.data.id
                    batch(state.data).then((res) => {
                      if (res.code === 10000) {
                        message.success('添加成功')
                        state.data = {
                          exceptionSubType: '',
                          exceptionExplain: '',
                          type: '',
                          orderVehicleIds: []
                        }
                        onSwitch(false)
                      }
                      state.fromRef.resetFields()
                    })
                  }
                  state.loading = false
                })
              } else {
                batch(state.data).then((res) => {
                  if (res.code === 10000) {
                    message.success('添加成功')
                    state.data = {
                      exceptionSubType: '',
                      exceptionExplain: '',
                      type: ''
                    }
                    onSwitch(false)
                  }
                  state.loading = false

                  state.fromRef.resetFields()
                })
              }
              state.fileList = []
            })
            .catch(error => {
              console.log(error)
            })
          break
        case 2:
          state.trans.onSwitch(true)
          break
        case 3:
          if (state.fileList.length > 0) {
            const formData = new FormData()
            formData.append('file', state.fileList[0])
            upload(formData).then(res => {
              if (res.code === 10000) {
                state.data.fileId = res.data.id
                edit(state.data.orderVehicleId, state.data).then((res) => {
                  if (res.code === 10000) {
                    message.success('修改成功')
                    state.data = {
                      exceptionSubType: '',
                      exceptionExplain: '',
                      type: ''
                    }
                    onSwitch(false)
                  }
                  state.fromRef.resetFields()
                })
              }
              state.loading = false
            })
          }else{
            edit(state.data.orderVehicleId, state.data).then((res) => {
              if (res.code === 10000) {
                message.success('修改成功')
                state.data = {
                  exceptionSubType: '',
                  exceptionExplain: '',
                  type: ''
                }
                onSwitch(false)
              }
              state.fromRef.resetFields()
            })
          }
          break

        default:
          message.success('出现错误' + type)
      }
    }
    const onCar = (parameter) => {
      state.data.carNo = ''
      state.car = {}
      if (parameter.length === 1 && parameter.id != null) {
        state.car = parameter
      } else {
        state.data.orderVehicleIds = []
      }
      for (let i = 0; i < parameter.length; i++) {
        if (parameter.length === 1 && parameter.id != null) {
        } else {
          state.data.orderVehicleIds.push(parameter[i].orderVehicleId)
        }
        state.data.carNo = state.data.carNo + parameter[i].vinNo + ' '
      }
    }
    const onData = (parameter) => {
      state.data.carNo = parameter.carNo
      state.data.orderVehicleId = parameter.orderVehicleId
      state.data.id = parameter.id
      state.data.exceptionExplain = parameter.exceptionExplain
      state.data.exceptionType = parameter.exceptionType.value
      state.data.exceptionSubType = parameter.exceptionSubType.value
      state.data.fileId = parameter.fileId
      state.data.fileRealUrl = parameter.fileRealUrl

      state.datatype = true
      state.fileList = {
        lastModified: 1605150489029,
        name: '5.png',
        size: 222596,
        type: 'image/png',
        url: parameter.fileUrl,
        uid: 'vc-upload-1625383591940-5'
      }
      onSwitch(true)
    }
    const handleRemove = file => {
      const index = state.fileList.indexOf(file)
      const newfileList = state.fileList.slice()
      newfileList.splice(index, 1)
      state.fileList = newfileList
    }

    const beforeUpload = file => {
      state.fileList = []
      state.fileList = [file]
      return false
    }
    const handlePreview = () => {
      state.preview = true
    }
    return {
      onCar,
      onData,
      beforeUpload,
      onSwitch,
      handleRemove,
      ...toRefs(state),
      handlePreview,
      onclick
    }
  }
}
</script>
